<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-2">
        The standard Gibbs energy change,
        <stemble-latex content="$\Delta\text{G}^\circ_\text{rxn}$," /> is equal to
        <stemble-latex content="$-32.9\,\text{kJ/mol}$" /> for the following reaction at
        <stemble-latex content="$25\,^\circ\text{C:}$" />
      </p>

      <p class="mb-5 pl-8">
        <stemble-latex content="$\ce{N2(g)}  +  \ce{3 H2(g)}  <=> \ce{2 NH3(g)}$" />
      </p>

      <p class="mb-2">
        a) What is the Gibbs energy change,
        <stemble-latex content="$\Delta\text{G}_\text{rxn},$" />
        when the partial pressures are
        <number-value :value="PN2" unit="\text{atm}" />
        for
        <chemical-latex content="N2," />
        <number-value :value="PH2" unit="\text{atm}" />
        for
        <chemical-latex content="H2," />
        and
        <number-value :value="PNH3" unit="\text{atm}" />
        for
        <stemble-latex content="$\ce{NH3}.$" />
      </p>

      <calculation-input
        v-model="inputs.deltaG"
        class="mb-5"
        prepend-text="$\Delta\text{G}_\text{rxn}:$"
        append-text="$\text{kJ/mol}$"
        :disabled="!allowEditing"
      />
    </v-form>

    <p class="mb-2">
      b) Is the system at equilibrium? If not, will more products or reactants be formed to reach
      equilibrium?
    </p>

    <s-textarea v-model="inputs.explanation" class="mb-n6" outlined />
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import StembleLatex from '@/tasks/components/StembleLatex';
import NumberValue from '../NumberValue';
import STextarea from '@/common/components/STextarea.vue';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';

export default {
  name: 'chemCPP1220_Task98',
  components: {
    ChemicalLatex,
    CalculationInput,
    NumberValue,
    STextarea,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        deltaG: null,
        explanation: null,
      },
    };
  },
  computed: {
    PN2() {
      return this.taskState.getValueBySymbol('PN2').content;
    },
    PH2() {
      return this.taskState.getValueBySymbol('PH2').content;
    },
    PNH3() {
      return this.taskState.getValueBySymbol('PNH3').content;
    },
  },
};
</script>
